<template>
  <div></div>
</template>

<script>
export default {
  name: "Whitepaper",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
